<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        fluid
        src="@/assets/images/celcomLogo/logo.png"
        alt="Logo Celcom"
        style="height: 30px"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3" v-if="payState === true">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Pago exitoso!!!
        </h2>
        <p class="mb-2">
          Su cobro se ha realizado con exito!.
        </p>

        <h4 v-if="callback != ''">
          <a :href="callback"
            >En 5 segundos lo redirigiremos al sitio, o haga click aquí</a
          >
        </h4>

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
    </div>
    <div class="misc-inner p-2 p-sm-3" v-if="payState === false">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          No puedes recargar la pagina
        </h2>
        <p class="mb-2">
          El cobro se ha realizado con anterioridad.
        </p>
        <h4 v-if="callback != ''">
          <a :href="callback"
            >En 5 segundos lo redirigiremos al sitio, o haga click aquí</a
          >
        </h4>

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import store from '@/store/index';

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      payState: null,
      callback: '',
    };
  },
  created() {
    this.payState = this.$store.state.appConfig.pay;
    this.$store.commit('appConfig/savePayment', true);
  },
  mounted() {
   
    let cb = window.localStorage.getItem('cb');
    if (cb != null) {
      this.callback = cb;
      window.localStorage.removeItem('cb');
      if (this.$store.state.appConfig.pay) {
        if (cb.length > 0) {
          setTimeout(() => {
            window.location.href = this.callback;
          }, 5000);
        }
      }
    }
  },
  updated() {
    this.$store.commit('appConfig/savePayment', false);
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg');
        return this.downImg;
      }
      return this.downImg;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
